<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import config from './config'

export default {
  name: 'RentalSales',
  setup() {
    const {
      MODULE_NAME, MODULE_NAME_CATALOG, store, model, catalog, MODULE_NAME_ATTACHMENT, attachmentModel,
    } = config()

    // Register on enter section
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, model)
    if (!store.hasModule(MODULE_NAME_CATALOG)) store.registerModule(MODULE_NAME_CATALOG, catalog)
    if (!store.hasModule(MODULE_NAME_ATTACHMENT)) store.registerModule(MODULE_NAME_ATTACHMENT, attachmentModel)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
      if (store.hasModule(MODULE_NAME_CATALOG)) store.unregisterModule(MODULE_NAME_CATALOG)
      if (store.hasModule(MODULE_NAME_ATTACHMENT)) store.unregisterModule(MODULE_NAME_ATTACHMENT)
    })
  },
}

</script>
